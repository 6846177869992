<template>
    <picture
        v-if="usePicture"
        ref="rootEl"
        :class="cssClassList">
        <slot></slot>
        <img
            v-bind="$attrs"
            :class="cssClassList"
            :src="srcImage"
            :srcset="srcsetImage"
            @load="onLoad"
            @error="onError">
    </picture>
    <img
        v-else
        ref="rootEl"
        v-bind="$attrs"
        :class="cssClassList"
        :src="srcImage"
        :srcset="srcsetImage"
        @load="onLoad"
        @error="onError">
</template>

<script lang="ts" setup>
/**
 * Adapted from https://github.com/alexjoverm/v-lazy-image/blob/master/v-lazy-image/index-v2.js
 */
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';

const props = defineProps({
    src: {
        type: String,
        required: true
    },
    srcPlaceholder: {
        type: String,
        default: "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
    },
    srcset: {
        type: String
    },
    intersectionOptions: {
        type: Object,
        default: () => ({})
    },
    usePicture: {
        type: Boolean,
        default: false
    }
})

const emit = defineEmits<{
    (e: 'intersected'): void,
    (e: 'error', value?: HTMLImageElement|HTMLPictureElement): void,
    (e: 'load', value?: HTMLImageElement|HTMLPictureElement): void,
}>()

let observer: IntersectionObserver;
const intersected = ref(false);
const loaded = ref(false);
const rootEl = ref<HTMLImageElement|HTMLPictureElement>();

const srcImage = computed(() => intersected.value && props.src ? props.src : props.srcPlaceholder);
const srcsetImage = computed(() => intersected.value && props.srcset ? props.srcset : undefined);
const cssClassList = computed(() => {
    const classList = ['v-lazy-image'];
    if (loaded.value) {
        classList.push('v-lazy-image-loaded');
    }
    return classList;
})

const onLoad = () => {
    if (rootEl.value && rootEl.value.getAttribute('src') !== props.srcPlaceholder) {
        loaded.value = true;
        emit('load', rootEl.value)
    }
}

const onError = () => {
    emit('error', rootEl.value)
}

onMounted(() => {
    if (!('IntersectionObserver' in window)) return;
    if (!rootEl.value) return;

    observer = new IntersectionObserver(
        entries => {
            const image = entries[0];
            if (image.isIntersecting) {
                intersected.value = true;
                observer.disconnect();
                emit('intersected');
            }
        },
        props.intersectionOptions
    );
    
    observer.observe(rootEl.value);
})

onBeforeUnmount(() => {
    observer && observer.disconnect()
})
</script>